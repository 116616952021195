import initalSlickMovies from './helpers';

(function ($) {
    $(document).ready(() => {

        let $heroSlides = $('section.hero');
        let $movieSlides = $('.front-page-listing-movies .movies');

        if ($heroSlides.length > 0) {
            $heroSlides.slick({
                dots: true,
                infinite: false,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: false,
                customPaging : function(slider, i) {
                    return '<div class="dot"><span></span></div>';
                },
            });
        }

        if ($movieSlides.length > 0) {
            $movieSlides.slick(initalSlickMovies);
            toggleShowAll();
        }

        if($movieSlides.length) {

            $('.category-list .filter').on('click', function(e) {
                e.preventDefault();

                let filter = $(this).data('filter');

                $('.toggle-to-grid').removeClass('toggle-grid')

                $('.category-list .filter').removeClass('active');
                $(this).addClass('active');

                if(filter === 'all'){
                    $movieSlides.slick('slickUnfilter');
                    $movieSlides.slick('slickFilter', '.movie');
                } else {
                    $movieSlides.slick('slickUnfilter');
                    $movieSlides.slick('slickFilter', '.' + filter);
                }

                toggleShowAll();

            })

            $(document).on('click', '.toggle-to-grid', function(e) {

                let width = $('.movie.slick-slide.slick-active').width();

                e.preventDefault();
                $(this).toggleClass('toggle-grid')

                let slider = $(this).parent().siblings('.movies');

                if(!$(this).hasClass('toggle-grid')) {
                    slider.slick(initalSlickMovies);
                    slider.removeClass('grid')
                } else {
                    slider.slick('unslick');
                    slider.addClass('grid')
                    slider.find('.movie').each(function(){
                        $(this).width(width)
                    });
                }

            })

        }

        function toggleShowAll() {

            let slidesToShow = $movieSlides.slick('slickGetOption', 'slidesToShow');

            if(!$('.movies').hasClass('grid')) {

                $('.movies').each(function() {

                    let amountPerSlider = $(this).find('.slick-slide:not(.slick-cloned)').length;

                    if(amountPerSlider > slidesToShow) {
                        $(this).siblings('h3').find('span').css('display','inline');
                    } else {
                        $(this).siblings('h3').find('span').css('display','none');
                    }

                });

            } else {
                $('.movies').removeClass('grid')
            }


        }

    })
})(jQuery)










