import { setCookie } from  './helpers';
import initalSlickMovies from './helpers';

(function ($) {

    $(document).ready(() => {

        var searchingFilter = false;

        // toggle movie into my list
        $(document).on('click', '.add-to-my-list', function(e) {

            e.preventDefault();

            if($(this).hasClass('remove-from-list')) {
                $(this).removeClass('remove-from-list');
                $('.movies').find("[data-movie-id='" + $(this).data('movie-id') + "']").removeClass('remove-from-list');
                $(this).addClass('add');
            } else {
                $(this).addClass('remove-from-list');
                $(this).removeClass('add');
            }

            $.ajax({
                url: ajaxurl,
                type: 'post',
                data: {
                    action: 'add_to_my_list',
                    movie : $(this).data('movie-id')
                },
                error: function(response) {
                    console.log(response)
                },
                success: function(response) {
                    // console.log(response)
                    $('#my-list').remove();
                    $('.front-page-listing-movies').prepend(response)
                    $(document).find('#my-list .movies').slick(initalSlickMovies);
                }

            });

        });

        // set cookie for password page for school-viewing
        $('#school-movie-password').on('submit', function(e) {

            e.preventDefault();

            let password = $('#school-password').val();
            let watcher = $('#watcher').val();
            let amount = $('#amount').val();
            let grade = $('#grade').val();
            let zip = $('#zip-code').val();
            let movieId = $('#school-password').data('school-movie-id');

            $.ajax({
                url: ajaxurl,
                type: 'post',
                data: {
                    action: 'collect_school_movie_data',
                    movie_id : movieId,
                    password : password,
                    zip_code : zip,
                    watcher : watcher,
                    amount : amount,
                    grade : grade
                },
                error: function(response) {
                    console.log(response)
                },
                success: function(response) {
                    if(response.error.length) {

                        $(document).find('ul.error').remove()

                        setTimeout(function(){
                            $( response.error ).insertBefore( $('#school-movie-password') );
                        }, 500);

                    } else {
                        setCookie('movie-password-' + movieId, password);
                        window.location.reload()
                    }
                }

            });

        });

        $('.filter-search-wrap .close').on('click', function(e){
            e.preventDefault()
            reset();
            $('#filter-search').val('')
            window.dispatchEvent(new Event('resize'));
        });

        function reset() {
            $('#filter-listing').remove()
            $('.category-list').show()
            $('.front-page-listing-movies').show()
            $('#my-list .movies').not('.slick-initialized').slick(initalSlickMovies);
        }

        $('#filter-search').on('input', function(e) {

            let inputSearch = $(this).val();

            setTimeout(function(){

                if(inputSearch.length >= 3) {

                    if(!searchingFilter) {

                        searchingFilter = true;

                        $.ajax({
                            url: ajaxurl,
                            type: 'post',
                            data: {
                                action: 'filter_movies',
                                filter : inputSearch,
                            },
                            error: function(response) {
                                console.log(response)
                            },
                            success: function(response) {

                                $('#filter-listing').remove()
                                $('<ul id="filter-listing"></ul>').insertAfter('.front-page-listing-movies')
                                $('.category-list').hide()
                                $('.front-page-listing-movies').hide()
                                $(document).find('#filter-listing').append(response)
                                $('#filter-listing .movies').slick(initalSlickMovies);

                                searchingFilter = false;

                            }

                        });

                    }

                } else {
                    reset();
                }

             }, 1000);





        });


    })

})(jQuery)







