(function ($) {
    $(document).ready(() => {

        //sign in toggle on top
        $('a.sign-in').on('click', function (e) {
            e.preventDefault();
            $('#sign-in-form').toggle();
            window.scrollTo(0, 0);
        });

        $('#sign-in-form #close').on('click', function (e) {
            $('#sign-in-form').hide();
        });

        //faq toggle on /faq
        $('.faq-questions .question').on('click', function(e) {
            e.preventDefault();
            $('.faq-questions li').removeClass('open');
            $(this).parent().addClass('open');
        })

        //toggle mobile menu
        $('#menu-toggle-mobile').on('click', function(e) {
            e.preventDefault();
            $('#side-menu').toggle();
        })

        $('.movie .cover .heart').on('click', function(e) {
            e.preventDefault();
        });

        $('.movie-preview .svg-icon').on('click', function() {
            $(this).parent().css("background-image", "");
            let width = $(this).siblings('.overlay').width()
            let height = $(this).siblings('.overlay').height()
            let iframe = $(this).siblings('.movie-embed').find('iframe')
            let src = iframe.attr('src');

            //iframe.attr('src', src + '&autoplay=1');

            iframe.width(width)
            iframe.height(height)

            $('.movie-embed').show();
            $('.movie-embed iframe').css('visibility', 'initial')
            $(this).hide();
            $(this).siblings('.title-holder').hide();
            $(this).siblings('.overlay').hide();
            $(this).siblings('.disclaimer').hide();

        });


    })

})(jQuery)







